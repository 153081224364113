<template lang="">
  <div class="allTotals-area">
    <h2>{{$t(`message.AllTotals.title`)}}</h2>
    <div class=" allTotals-are-content">
      <div class="allTotals-content">
        <p>{{$t(`message.AllTotals.h3[0]`)}}</p>
        <!-- <ul class="flex-ul" v-for="(item,index) in aboutFileOperate" :key="item.command"> -->
        <ul class="flex-ul">
          <!-- <li class="cell cursor" @mouseenter="handleEnter(item,index)" @mouseleave="handleLeave(item,index)"
            @click="changeOperate(item,scoped.row)">
            <img :src="item.active?item.hoverurl:item.url">
            <span>{{item.text}}</span>
          </li> -->
          <li class="cell cursor img1" @click="goPath('/pdf-combiner?from_funlist','mergepdf')">
            <img src="../assets/allTotals/mergePDF.svg" alt="" />
            <!-- <span>{{$t(`message.FunList[2]`)}}</span> -->
            <span>{{$t(`message.FunList[2]`)}}</span>

          </li>
          <li class="cell cursor img2"
            @click="goPath('https://pdnob.passfab.com/split-pdf?from_toplist','splitpdf',true)">
            <img src="../assets/allTotals/splitPDF.svg" alt="" />
            <!-- <span>{{$t(`message.FunList[1]`)}}</span> -->
            <span>{{$t(`message.FunList[1]`)}}</span>

          </li>
          <li class=" cell cursor img3"
            @click="goPath('https://pdnob.passfab.com/extract-pages-from-pdf?from_funlist','extractpages',true)">
            <img src="../assets/allTotals/extractPages.svg" alt="" />
            <!-- <span>{{$t(`message.FunList[9]`)}}</span> -->
            <span>{{$t(`message.FunList[9]`)}}</span>

          </li>
          <li class="cell cursor img4" style="width: 165px;"
            @click="goPath('https://pdnob.passfab.com/extract-images-from-pdf?from_funlist','extractimages',true)">
            <img src="../assets/allTotals/extractImages.svg" alt="" />
            <!-- <span>{{$t(`message.FunList[8]`)}}</span> -->
            <span>{{$t(`message.FunList[8]`)}}</span>
          </li>
        </ul>
      </div>
      <div class="allTotals-content">
        <p>{{$t(`message.AllTotals.h3[4]`)}}</p>
        <ul class="flex-ul">
          <li class="cell cursor img5" @click="goPath('compress-pdf?from_funlist','compresspdf')">
            <img src="../assets/allTotals/compressPDF.svg" alt="" />
            <!-- <span>{{$t(`message.FunList[4]`)}}</span> -->
            <span>{{$t(`message.FunList[4]`)}}</span>
          </li>
        </ul>
      </div>
      <div class="allTotals-content">
        <p>{{$t(`message.AllTotals.h3[1]`)}}</p>
        <ul class="flex-ul">
          <li class="cell cursor img6" @click="goPath('/convert?from_toplist&to_word','pdftoword')">
            <img src="../assets/allTotals/PTW.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[0]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[0]`)}}</span> -->
          </li>
          <li class="cell cursor img7" @click="goPath('/convert?from_toplist&to_jpg','pdftojpg')">
            <img src="../assets/allTotals/PTJ.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[4]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[4]`)}}</span> -->
          </li>
          <li class="cell cursor img8" @click="goPath('/convert?from_toplist&to_excel','pdftoexcel')">
            <img src="../assets/allTotals/PTE.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[1]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[1]`)}}</span> -->
          </li>
          <li class="cell cursor img9" @click="goPath('/convert?from_toplist&to_ppt','pdftoppt')">
            <img src="../assets/allTotals/PDTPP.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[2]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[2]`)}}</span> -->
          </li>
          <li class="cell cursor img10" @click="goPath('/convert?from_toplist&to_text','pdftotxt')">
            <img src="../assets/allTotals/PTT.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[3]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[3]`)}}</span> -->
          </li>
          <li class="cell cursor img11" @click="goPath('/convert?from_toplist&to_html','pdftohtml')">
            <img src="../assets/allTotals/PTH.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[5]`)}}</span>

            <!-- <span>{{$t(`message.Navigation.pdf.info[5]`)}}</span> -->
          </li>
        </ul>
      </div>
      <div class="allTotals-content">
        <p>{{$t(`message.AllTotals.h3[5]`)}}</p>
        <ul class="flex-ul">
          <li class="cell cursor img12" @click="goPath('/convert?from_toplist&from_other_word','wordtopdf')">
            <img src="../assets/allTotals/WTP.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[6]`)}}</span>
            <!-- <span>{{$t(`message.Navigation.pdf.info[6]`)}}</span> -->
          </li>
          <li class="cell cursor img13" @click="goPath('/convert?from_toplist&from_other_jpg','jpgtopdf')">
            <img src="../assets/allTotals/ITP.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[10]`)}}
            </span>
            <!-- <span>{{$t(`message.Navigation.pdf.info[10]`)}}</span> -->
          </li>
          <li class="cell cursor img14" @click="goPath('/convert?from_toplist&from_other_excel','exceltopdf')">
            <img src="../assets/allTotals/ETP.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[7]`)}}
            </span>
            <!-- <span>{{$t(`message.Navigation.pdf.info[7]`)}}</span> -->
          </li>
          <li class="cell cursor img15" @click="goPath('/convert?from_toplist&from_other_ppt','ppttopdf')">
            <img src="../assets/allTotals/PPTPD.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[8]`)}}
            </span>
            <!-- <span>{{$t(`message.Navigation.pdf.info[8]`)}}</span> -->
          </li>
          <li class="cell cursor img16" @click="goPath('/convert?from_toplist&from_other_text','txttopdf')">
            <img src="../assets/allTotals/TTP.svg" alt="" />
            <span>{{$t(`message.Navigation.pdf.info[9]`)}}
            </span>
            <!-- <span>{{$t(`message.Navigation.pdf.info[9]`)}}</span> -->
          </li>
          <li class="cell cursor img21" @click="goPath('https://pdnob.passfab.com/convert','htmltopdf',true)">
            <img src="../assets/allTotals/HTP.svg" alt />
            <span>{{$t(`message.Navigation.pdf.info[11]`)}}
            </span>
          </li>
        </ul>
      </div>
      <div class="allTotals-content">
        <p>{{$t(`message.AllTotals.h3[2]`)}}</p>
        <ul class="flex-ul">
          <li class="cell cursor img22" @click="goPath('/remove-watermark?from_funlist','removewatermark',true)">
            <img src="../assets/allTotals/RW.svg" alt />
            <span>{{$t(`message.FunList[10]`)}}</span>
          </li>
          <img src="../assets/allTotals/hot.svg" alt="" style="position: relative; top: -45px;left: 170px;">
          <li class="cell cursor img17" style="width: 180px;margin-top: 0;"
            @click="goPath('https://pdnob.passfab.com/add-watermark-to-pdf?from_funlist','addwatermark',true)">
            <img src="../assets/allTotals/AW.svg" alt="" />
            <span>{{$t(`message.FunList[3]`)}}</span>

            <!-- <span>{{$t(`message.FunList[3]`)}}</span> -->
          </li>
          <li class="cell cursor img18"
            @click="goPath('https://pdnob.passfab.com/rotate-pdf?from_funlist','rotatepdf',true)">
            <img src="../assets/allTotals/RP.svg" alt="" />
            <span>{{$t(`message.FunList[5]`)}}</span>

            <!-- <span>{{$t(`message.FunList[5]`)}}</span> -->
          </li>
          <li class="cell cursor img19"
            @click="goPath('https://pdnob.passfab.com/delete-pages-from-pdf?from_funlist','deletepages',true)">
            <img src="../assets/allTotals/DP.svg" alt="" />
            <span>{{$t(`message.FunList[7]`)}}</span>
            <!-- <span>{{$t(`message.FunList[7]`)}}</span> -->
          </li>
        </ul>
      </div>
      <div class="allTotals-content" style="margin-right: 0;">
        <p>{{$t(`message.AllTotals.h3[3]`)}}</p>
        <ul class="flex-ul">
          <li class="cell cursor img20"
            @click="goPath('https://pdnob.passfab.com/password-protect-pdf?from_funlist','passwordprotect',true)">
            <img src="../assets/allTotals/PP.svg" alt="" />
            <span>{{$t(`message.FunList[6]`)}}</span>

            <!-- <span>{{$t(`message.FunList[6]`)}}</span> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        footType: '',
        aboutFileOperate: [
          {
            command: 'Rename',
            text: this.$t('message.FunList[2]'),
            url: require('../assets/allTotals/splitPDF.svg'),
            hoverurl: require('../assets/allTotals/extractPages.svg'),
            active: false,
          },
          {
            command: 'Download',
            text: this.$t('message.FunList[1]'),
            url: require('../assets/allTotals/splitPDF.svg'),
            hoverurl: require('../assets/allTotals/extractPages.svg'),
            active: false,
          },
          {
            command: 'Delete',
            text: this.$t('message.FunList[9]'),
            url: require('../assets/allTotals/splitPDF.svg'),
            hoverurl: require('../assets/allTotals/extractPages.svg'),
            active: false,
          },
          {
            command: 'Move',
            text: this.$t('message.FunList[8]'),
            url: require('../assets/allTotals/splitPDF.svg'),
            hoverurl: require('../assets/allTotals/extractPages.svg'),
            active: false,
          },
        ],
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.footType = this.$store.state.local
      })
    },
    methods: {
      checkAppState() {
        return this.$store.state.appState;
      },
      handleEnter(item, index) {
        // item.value.active = true
        this.$nextTick(() => {
          this.aboutFileOperate[index].active = true
        })
        // //console.log(item.active, index)
      },
      handleLeave(item, index) {
        // item.value.active = false
        this.$nextTick(() => {
          this.aboutFileOperate[index].active = false
        })
        // this.aboutFileOperate[index].active = false
        // //console.log(item.active, index)
      },
      goPath(path, name, isHref) {
        //isHref判断是否是老版本的地址
        let _this = this
        // //console.log("_this.$parent:", _this);
        // //console.log("_this.$parent:", _this.$parent);
        // //console.log("_this.$store:", _this.$store.state.isDown);
        if (this.$store.state.isDown == 2) {
          // //console.log("nav2");
          this.$confirm(this.$t(`message.commonTip.alertTip[6].poptext`), {
            confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
            cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
            type: "none",
            closeOnClickModal: false
          })
            .then(() => {
              if (isHref) {
                window.location.href = path
              } else {
                // //console.log("nav2Then");
                // _this.$store.commit('changeUploadingFlag', false)
                // _this.$store.commit('changeAppState', 'start')
                // // //console.log("path2:", path, _this.checkAppState());
                // _this.$store.commit('changeDown', 0)
                // _this.$router.push(path)
                window.location.href = _this.$store.state.locationUrl + path

              }
              document.documentElement.scrollTop = 0

            })
            .catch(() => {
              // //console.log("nav2Cancel");

            });
        } else if (this.$store.state.isDown == 1) {
          // //console.log("nav1");
          this.$confirm("Leaving or reloading the page will lose data, do you want to leave the current page?", {
            confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
            cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
            type: "none",
            closeOnClickModal: false
          })
            .then(() => {
              if (isHref) {
                window.location.href = path
              } else {
                window.location.href = _this.$store.state.locationUrl + path

                // //console.log("path1:", path);
                // _this.$store.commit('changeAppState', 'start')
                // _this.$store.commit('changeDown', 0)
                // _this.$store.commit('changeUploadingFlag', false)

                // _this.$router.push(path)
              }
              document.documentElement.scrollTop = 0

            })
            .catch(() => {
            });
        } else {
          // //console.log("indexOf:", path, _this.checkAppState());
          if (_this.checkAppState() != "start" && _this.checkAppState() != "failConvert" && _this.checkAppState() != "failUpload") {
            // //console.log("------", _this.checkAppState());
            this.$confirm(this.$t(`message.commonTip.alertTip[5].poptext`), {
              confirmButtonText: this.$t(`message.commonTip.alertTip[5].confirmButtonText`),
              cancelButtonText: this.$t(`message.commonTip.alertTip[5].cancelButtonText`),
              type: "none",
              closeOnClickModal: false
            })
              .then(() => {
                if (isHref) {
                  window.location.href = path
                } else {
                  window.location.href = _this.$store.state.locationUrl + path

                  // //console.log("path11111:", path);
                  // _this.$router.push(path)

                  // _this.$store.commit('changeAppState', 'start')
                  // _this.$store.commit('changeDown', 0)
                  // _this.$store.commit('changeUploadingFlag', false)

                }
                document.documentElement.scrollTop = 0

              })
              .catch(() => {
              });
          } else {
            if (isHref) {
              window.location.href = path
            } else {
              window.location.href = _this.$store.state.locationUrl + path

              // _this.$router.push(path)
              document.documentElement.scrollTop = 0

            }
          }
        }
        this.$gtag.event("tools_choose", {
          event_category: "function",
          event_label: name,
        });
        // document.documentElement.scrollTop = 0
      }
    },
  }
</script>
<style lang="scss" scoped>
  .allTotals-area {
    padding: 128px 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSI1NzYiIHZpZXdCb3g9IjAgMCAxOTIwIDU3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE5MjAiIGhlaWdodD0iNTc2IiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNjA4MF83NTc5KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzYwODBfNzU3OSIgeDE9Ijk2MCIgeTE9IjAiIHgyPSI5NjAiIHkyPSI1NzYiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Y1RjdGRCIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGNUY3RkQiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
    background-size: cover;
    height: 716px;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      text-align: center;
      color: #3d454d;
    }

    .allTotals-are-content {
      display: flex;
      padding-top: 80px;
      justify-content: center;
      margin: 0 auto;
      max-height: 1224px;

      .allTotals-content {
        margin-right: 40px;
        width: 190px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #8fa0b2;
          text-align: left;
        }
      }

      .flex-ul {
        /* display: flex;
        flex-direction: column; */
        padding-left: 0px;
      }

      .cell {
        /* max-width: 280px; */
        margin-top: 25px;
        display: flex;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;

        &:hover {
          background-color: #ecefff;
          // a {
          //   color: #4260ff;
          text-decoration: underline;
          // }

          // span {
          //   color: #4260ff;
          //   text-decoration: underline;
          // }
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #3d454d;
          margin-left: 10px;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #3d454d;
        }

        img {
          margin: 4px 10px 0 4px;
          width: 24px;
          height: 24px;
          // vertical-align: middle;
        }
      }

      // .img1 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AmergePDF.svg);
      //     }
      //   }
      // }

      // .img2 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AsplitPDF.svg);
      //     }
      //   }
      // }

      // .img3 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AextractPages.svg);
      //     }
      //   }
      // }

      // .img4 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AextractImages.svg);
      //     }
      //   }
      // }

      // .img5 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AcompressPDF.svg);
      //     }
      //   }
      // }

      // .img6 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APTW.svg);
      //     }
      //   }
      // }

      // .img7 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APTJ.svg);
      //     }
      //   }
      // }

      // .img8 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APTE.svg);
      //     }
      //   }
      // }

      // .img9 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APDTPP.svg);
      //     }
      //   }
      // }

      // .img10 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APTT.svg);
      //     }
      //   }
      // }

      // .img11 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APTH.svg);
      //     }
      //   }
      // }

      // .img12 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AWTP.svg);
      //     }
      //   }
      // }

      // .img13 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AITP.svg);
      //     }
      //   }
      // }

      // .img14 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AETP.svg);
      //     }
      //   }
      // }

      // .img15 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APPTPD.svg);
      //     }
      //   }
      // }

      // .img16 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/ATTP.svg);
      //     }
      //   }
      // }

      // .img17 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/AAW.svg);
      //     }
      //   }
      // }

      // .img18 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/ARP.svg);
      //     }
      //   }
      // }

      // .img19 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/ADP.svg);
      //     }
      //   }
      // }

      // .img20 {
      //   &:hover {
      //     img {
      //       content: url(../assets/allTotals/APP.svg);
      //     }
      //   }
      // }
    }
  }
</style>