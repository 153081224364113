<template>
  <div class="function">
    <div class="box" v-for="(item,index) in menuFunction" :key="index">
      <p class="title">{{item.title}}</p>
      <div class="itemDiv">
        <div class="item-cell" v-for="(d,i) in item.data" :key="i" @click="goPath(d)">
          <img :src="d.new" alt="" class="newImg" v-if="d.new">
          <img :src="d.img" alt="" class="centerimg">
          <p>{{d.name}}</p>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
  import { gaEvent } from "@/utils/gaEvent";
  export default {
    data() {
      return {
        pageName: "",
        menuFunction: [],
        activeClass: ''
      }
    },
    mounted() {
      this.menuFunction = this.$store.state.menuListAll
    },
    methods: {
      handleOpen() {

      },
      handleClose() {

      },
      goPath(item) {
        let _this = this

        console.log("item:", item);
        let name = item.name.toLowerCase().replace(/ /g, '_')
        let navn = item.name.replace(/ /g, '_')
        let type = this.$store.state.menuIndex == 0 ? 'all' : 'others'
        console.log("item.name.replace(/ /g, '_'):", navn);
        this.$store.commit('changeCurrentPage', name)
        this.$store.commit('changenavName', navn)
        console.log("changeCurrentPage:", this.$store.state.currentPage);
        console.log("changenavName:", this.$store.state.navName);
        gaEvent(name, {
          event_category: "Select_function",
          event_label: type,
        });
        console.log("name:", name);
        window.location.href = _this.$store.state.locationUrl + item.path

        // //isHref判断是否是老版本的地址
        // if (this.$store.state.isDown == 2) {
        //   // //console.log("nav2");
        //   this.$confirm(this.$t(`message.commonTip.alertTip[6].poptext`), {
        //     confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
        //     cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
        //     type: "none",
        //     closeOnClickModal: false
        //   })
        //     .then(() => {
        //       window.location.href = _this.$store.state.locationUrl + path

        //       document.documentElement.scrollTop = 0

        //     })
        //     .catch(() => {

        //     });
        // } else if (this.$store.state.isDown == 1) {
        //   // //console.log("nav1");
        //   window.location.href = _this.$store.state.locationUrl + path

        // } else {
        //   console.log("indexOf:", path, _this.checkAppState());
        //   // if (path.indexOf("convert") != -1 && _this.checkAppState() != "start") {
        //   if (_this.checkAppState() != "start" && _this.checkAppState() != "failConvert" && _this.checkAppState() != "failUpload") {
        //     console.log("------", _this.checkAppState());
        //     this.$confirm(this.$t(`message.commonTip.alertTip[5].poptext`), {
        //       confirmButtonText: this.$t(`message.commonTip.alertTip[5].confirmButtonText`),
        //       cancelButtonText: this.$t(`message.commonTip.alertTip[5].cancelButtonText`),
        //       type: "none",
        //       closeOnClickModal: false
        //     })
        //       .then(() => {
        //         console.log("_this.$route.path3:");
        //         window.location.href = _this.$store.state.locationUrl + path
        //         document.documentElement.scrollTop = 0

        //       })
        //       .catch(() => {
        //       });
        //   } else {
        //     console.log("_this.$route.path4:");
        //     console.log(_this.$route);
        //     window.location.href = _this.$store.state.locationUrl + path

        //   }
        // }
        // this.$gtag.event("navigation_choose", {
        //   event_category: "function",
        //   event_label: name,
        // });

      },
    },
    watch: {
      '$store.state.menuIndex': {
        handler(val) {
          console.log("val:", val, this.$store.state.menuListAll);
          if (val == 0) {
            this.menuFunction = this.$store.state.menuListAll
          } else if (val == 1) {
            this.menuFunction = [].concat(this.$store.state.menuListAll[1])
          } else if (val == 2) {
            this.menuFunction = [].concat(this.$store.state.menuListAll[2])
          } else if (val == 3) {
            console.log("90909090:", this.$store.state.menuListAll[3]);
            this.menuFunction = [].concat(this.$store.state.menuListAll[3])
          }
        },
        deep: true,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .function {
    width: 1004px;
    height: 548px;
    background: #EEF5FF;
    /* background: red; */
    box-shadow: 0px 10px 16px rgba(84, 82, 246, 0.08);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-bottom: 16px;

    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f5f5f5 #fff;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 16px;
      background-color: #f5f5f5;
    }

    /*定义滚动条轨道
 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    /*定义滑块
 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: rgba(0, 22, 56, 0.1);
    }

    &::-webkit-scrollbar {
      display: none;
    }

  }

  .box {
    width: 940px;
    margin-left: 24px;
    padding-top: 16px;
  }

  .title {
    font-family: 'Microsoft YaHei UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(29, 29, 31, 0.5);
    margin-bottom: 16px;
    margin: 8px 0 0px 16px;
  }

  .itemDiv {
    display: flex;
    flex-wrap: wrap;

    .item-cell {
      width: 172px;
      height: 110px;
      cursor: pointer;
      position: relative;
      background: #FFFFFF;
      border-radius: 8px;
      margin: 16px 0 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: 0px 6px 16px rgba(44, 43, 98, 0.15);

        p {
          color: #5452F6;
        }
      }

      .newImg {
        position: absolute;
        top: 0;
        right: 0;
      }

      .centerimg {
        width: 60px;
        height: 42px;
        margin-bottom: 16px;
      }

      p {
        font-family: 'Microsoft YaHei UI';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;

        color: #1D1D1F;
      }
    }
  }
</style>