// 为了防止警告信息，直接指向到js不会提示警告信息
import { createI18n } from 'vue-i18n'
import messages from '../language/index.js'
const i18n = createI18n({
  // 定义的语言 
  // locale:"zh",
  locale:"en",
  // 引入各种不同的配置，可直接写在这里
  messages,
  globalInjection:true,
  // messages: messages,
  // 调用方法
  $t(key) {
    return this.messages[this.locale.value][key];
  }
})
export default i18n;