<template lang="">
  <div class="review-area">
    <h2>{{$t(`message.Reviews.title`)}}</h2>
    <div class="review-are-content">
      <el-carousel ref="carousel" arrow="never" :autoplay="autoplay" @mouseEnter="over" @mouseLeave="out" height="100%"
        indicator-position="none">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="review-area-content">
            <!-- <img :src="item.src" alt=""> -->
            <img v-lazy="item.src" alt="">
            <div class="review-right">
              <p>{{item.text}}</p>
              <p>--{{item.name}}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="cellImg">
        <img src="../assets/reviews/right.svg" alt="" @click="prev()" />
        <img src="../assets/reviews/left.svg" alt="" @click="next()" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        footType: '',
        list: [
          {
            src: require('../assets/reviews/Luna.png'),
            text: this.$t('message.Reviews.p[0]'),
            name: this.$t('message.Reviews.s[0]'),
          },
          {
            src: require('../assets/reviews/Lola.png'),
            text: this.$t('message.Reviews.p[1]'),
            name: this.$t('message.Reviews.s[1]'),
          },
          {
            src: require('../assets/reviews/Peter.png'),
            text: this.$t('message.Reviews.p[2]'),
            name: this.$t('message.Reviews.s[2]'),
          },
        ],
        autoplay: true,
      }
    },
    computed: {},
    methods: {
      changePage(index) {
        this.listIndex = index
      },
      //移除
      out() {
        this.autoplay = true
      },
      //移入
      over() {
        this.autoplay = false
      },
      prev() {
        ////console.log("this.$refs.carousel", this.$refs.carousel);
        this.$refs.carousel.next()
      },
      next() {
        this.$refs.carousel.prev()
      },
    },
    mounted() {
      // this.$nextTick(() => {
      //   this.footType = this.$store.state.local
      // }),
      // this.setTimer()
    },
  }
</script>
<style lang="scss" scoped>
  .review-area {
    /* padding: 120px 0; */
    padding: 128px 0 0 0;
    height: 841px;

    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      text-align: center;
      color: #3d454d;
      margin: 0;
    }

    .review-are-content {
      /* display: flex; */
      max-width: 1224px;
      height: 461px;
      /* height: 567px; */
      /* margin: 0 auto; */
      align-items: center;
      margin: 62px auto 122px auto;

      .review-area-content {
        display: flex;
        align-items: center;

        .review-right {
          margin-left: 66px;
          padding: 50px 0;
          width: 700px;

          p {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: #3d454d;
            text-align: left;
            margin-bottom: 20px;
            /* font-size: 18px;
            font-weight: 700;
            color: #525c66;
            margin-left: 10px;
            text-align: left; */
            /* margin-top: 32px;
          min-height: 66px; */
          }
        }

        img {
          /* max-width: 456px;
        max-height: 320px; */
          max-width: 462px;
          max-height: 444px;
        }
      }

      .cellImg {
        z-index: 10;
        margin-top: -80px;
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;
        position: relative;

        img {
          margin-left: 10px;
          background: #f5f7fd;
          padding: 10px 10px;
          border-radius: 14px;
        }

        :first-child {
          &:hover {
            background: #cbd0e1;
          }
        }

        :nth-child(2) {
          &:hover {
            background: #cbd0e1;
          }
        }
      }
    }
  }

  .demonstration {
    color: var(--el-text-color-secondary) !important;
  }

  .el-carousel {
    width: 100%;
    height: 100%;
    /* background-color: #475669; */
  }

  .el-carousel__container {
    /* width: 100% !important;
    height: 100% !important; */
    height: 100% !important;
  }

  .el-carousel__item {
    /* display: block !important; */
  }

  .el-carousel__item h3 {
    color: #475669 !important;
    opacity: 0.75 !important;
    line-height: 150px !important;
    margin: 0 !important;
    text-align: center !important;
  }

  .el-carousel__item:nth-child(2n) {
    /* background-color: black !important; */
  }

  .el-carousel__item:nth-child(2n + 1) {
    /* background-color: black !important; */
  }
</style>