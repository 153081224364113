
function checkAppState() {
  return this.$store.state.appState;
}
//1-num 2-format 3-size 4-unable 5-upload(upload or conver) 6-download 7-img-format
function openPop(getthis, num, step) {
  if (num == 1) {
    getthis.$alert(
      "<p class='pop-tit'>" + getthis.$t(`message.commonTip.alertTip[0].poptit`) + "</p><p class='pop-tit'>" + getthis.$t(`message.commonTip.alertTip[0].poptext`) + "</p>",
      "",
      {
        dangerouslyUseHTMLString: true,
        confirmButtonText: getthis.$t(`message.commonTip.alertTip[0].confirmButtonText`),
        callback: () => {
          getthis.fileList = [];
          getthis.$store.commit('changeAppState', 'start')
        },
      }
    );
  } else if (num == 2) {
    setTimeout(() => {
      getthis.$message({
        showClose: true,
        duration: 8000,
        type: "error",
        message: getthis.$t(`message.commonTip.alertTip[1].poptext`),
      })
    })
  } else if (num == 3) {
    setTimeout(() => {
      getthis.$message({
        showClose: true,
        duration: 8000,
        type: "error",
        message: getthis.$t(`message.commonTip.alertTip[2].poptext`),
      })
    })
  }
  else if (num == 4) {
    getthis.$message({
      showClose: true,
      duration: 8000,
      type: "error",
      message: getthis.$t(`message.commonTip.alertTip[3].poptext`),
    })
  } else if (num == 8) {
    const url = require("../assets/error.svg")
    getthis.$confirm("<img src = " + url + " style='vertical-align: middle;'/><span class='alertSpan'>" + getthis.$t(`message.commonTip.alertTip[4].poptext`) + "</span>", {
      confirmButtonText: getthis.$t(`message.commonTip.alertTip[4].confirmButtonText`),
      cancelButtonClass: 'noneBtn',
      dangerouslyUseHTMLString: true,
      closeOnClickModal: false
    }).then(() => {
      window.location.reload()
    }).catch(() => {
    });
  }
}


//跳转埋点
function navFunction(toRoute, fromRoute) {

}

function removeFile() {
  window.location.reload()
}
function removeSingleFile(fileNum) {
  console.log("11111:", fileNum, this.failJPG);
  if (this.fileList[fileNum].fail) this.failJPG--
  if (this.processNum) this.processNum--
  if (this.renderCount) this.renderCount--
  this.fileSize = this.fileSize - this.fileList[fileNum].size
  // //console.log("this.fileSize:", this.fileSize);
  this.fileList.splice(fileNum, 1);
  this.fileList == 0 ? this.$store.commit("changeAppState", "start") : ''
  this.clickFlag = false
  // this.uploadingFlag = false
  this.$store.commit('changeUploadingFlag', true)

}
export { checkAppState, openPop, removeFile, removeSingleFile, navFunction }