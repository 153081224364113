import {
	createRouter,
	// createWebHashHistory,
	createWebHistory
} from 'vue-router'
import store from '@/store'

// const redpath="https://pdf.niuxuezhang.cn/convert";
const redpath = "https://pdf-test.afirstsoft.cn/convert"
// 
// const redpath="https://www.passfab.tw/convert";

const routes = [
	{
		path: '/',
		redirect: redpath,
	},
	{
		path: '/pc-home',
		name: 'homePage',
		component: () => import( /* webpackChunkName: "convert" */ '../views/home.vue')
	},
	{
		path: '/convert',
		name: 'convert',
		component: () => import( /* webpackChunkName: "convert" */ '../views/convert.vue')
	},
	{
		path: '/pc-convert',
		name: 'pc-convert',
		component: () => import( /* webpackChunkName: "convert" */ '../views/pc/convert.vue')
	},
	{
		path: '/remove-watermark',
		name: 'removeWater',
		component: () => import( /* webpackChunkName: "addwater" */ '../views/removeWater.vue')
	},
	{
		path: '/pc-remove-watermark',
		name: 'pc-removeWater',
		component: () => import( /* webpackChunkName: "addwater" */ '../views/pc/removeWater.vue')
	},
	// {
	// 	path: '/split-pdf',
	// 	name: 'splitPDF',
	// 	component: () => import( /* webpackChunkName: "split" */ '../views/splitPDF.vue')
	// }, 
	{
		path: '/pdf-combiner',
		name: 'mergePDF',
		component: () => import( /* webpackChunkName: "merge" */ '../views/mergePDF.vue')
	},
	{
		path: '/pc-pdf-combiner',
		name: 'pc-mergePDF',
		component: () => import( /* webpackChunkName: "merge" */ '../views/pc/mergePDF.vue')
	},
	// {
	// 	path: '/add-watermark-to-pdf',
	// 	name: 'addWater',
	// 	component: () => import( /* webpackChunkName: "addwater" */ '../views/addWater.vue')
	// },
	{
		path: '/compress-pdf',
		name: 'compressPDF',

		component: () => import( /* webpackChunkName: "compress" */ '../views/compressPDF.vue')
	},
	{
		path: '/pc-compress-pdf',
		name: 'pc-compressPDF',

		component: () => import( /* webpackChunkName: "compress" */ '../views/pc/compressPDF.vue')
	},
	// {
	// 	path: '/rotate-pdf',
	// 	name: 'rotatePDF',

	// 	component: () => import( /* webpackChunkName: "rotate" */ '../views/rotatePDF.vue')
	// },
	// {
	// 	path: '/password-protect-pdf',
	// 	name: 'protectPDF',

	// 	component: () => import( /* webpackChunkName: "protect" */ '../views/protectPDF.vue')
	// },
	// {
	// 	path: '/delete-pages-from-pdf',
	// 	name: 'deletePDF',

	// 	component: () => import( /* webpackChunkName: "delete" */ '../views/deletePDF.vue')
	// },
	// {
	// 	path: '/extract-pages-from-pdf',
	// 	name: 'extractPage',

	// 	component: () => import( /* webpackChunkName: "extract" */ '../views/extractPage.vue')
	// },
	// {
	// 	path: '/extract-images-from-pdf',
	// 	name: 'extractImg',

	// 	component: () => import( /* webpackChunkName: "extract" */ '../views/extractImg.vue')
	// },
	{
		path: '/:pathMatch(.*)*',
		redirect: redpath,
	}
]

const router = createRouter({
	// createWebHashHistory  createWebHistory
	history: createWebHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	// if(window.location)
	// if (to.name == from.name) {
	// 	if (store.state.appState == 'converted') {
	// 		store.commit("changeAppState", 'operate');
	// 		return;

	// 	}
	// }
	/* 
	 convert 会重新刷新convert页面   
	 
	 
	 */
	// if ((store.state.fileType == "png" || store.state.fileType == "jpg" || store.state.fileType == "bmp" || store.state.fileType == "txt" || store.state.fileType == "xlsx" || store.state.fileType == "xls" || store.state.fileType == "doc" || store.state.fileType == "docx" || store.state.fileType == "ppt" || store.state.fileType == "pptx") && to.name == from.name) { //格式是png  并且是在covert页面刷新
	// 	store.commit("changeAppState", 'operate');
	// 	return;
	// }
	// store.commit("checkPDF", false);
	// store.commit("changeAppState", 'start');
	// if (to.fullPath.indexOf('pdnobUrl') > -1) {

	// 	store.commit("changeAppState", 'converted');
	// 	store.commit("changeDownUrl", to.fullPath.substring(to.fullPath.indexOf('pdnobUrl') + 9));
	// }
	// ////console.log("meirenl:", store.state.appState);

	next()
})

router.afterEach((to) => {
	// window.ga('set', 'page', to.path)
	// window.ga('send', 'pageview')

})
router.beforeEach((to, from, next) => {
	clearHttpRequestingList();
	next();
});

function clearHttpRequestingList() {
	// 路由切换检测是否强行中断， 强行中断时才向下执行
	////console.log('this.$httpRequestList')
	// if (this.$httpRequestList.length > 0) {
	//   this.$httpRequestList.forEach((item) => {
	//     // 给个标志，中断请求
	//     item('interrupt');
	//   })
	//   this.$httpRequestList = [];
	// }
}
export default router