import { createApp } from 'vue'
import App from './App.vue'
import PcApp from './PcApp.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import axios from './api/axios';
import * as common from './api/common.js';
// import renderPdf from './api/renderPdf';
import VueCookies from 'vue3-cookies'
import VueGtag from 'vue-gtag';
import { clickOutside } from "./utils/directive";
import vueI18n from './plugins/i18nPlugin.js'


// import PDF from 'pdfjs-dist'
import '../src/assets/style/common.scss'
import '../src/assets/style/rnui.scss'

import '../src/assets/style/openPop.scss'
import VueLazyload from 'vue-lazyload'

console.log("navigator.userAgent:", navigator.userAgent);
if (navigator.userAgent.indexOf('pdfName') > 0) {
  console.log("pc端");
  const pcapp = createApp(PcApp)

  // 自定义指令
  clickOutside(pcapp);
  pcapp.config.globalProperties.$http = axios
  pcapp.config.globalProperties.$checkAppState = common
  // pcapp.config.globalProperties.$VERSION = VERSION
  // app.config.globalProperties.$http = PDF

  pcapp.config.globalProperties.$httpRequestList = [];//  cancelToken中的cancel函数
  installElementPlus(pcapp)
  pcapp.use(VueGtag, {
    appName: 'PDNob PDF Win',
    config: {
      id: 'UA-238236071-2',
    }
  })
  pcapp.use(VueLazyload, {
    preLoad: 1.3, //预加载高度
    error: require('./assets/merge/loading.gif'), //错误展示图片
    loading: require('./assets/merge/loading.gif'), //加载等待图片
    attempt: 1 //尝试次数
  })
  pcapp.use(store).use(vueI18n).use(router).use(VueCookies, { expireTimes: "7d", }).mount('#app')
} else {
  console.log("web端");
  const app = createApp(App)
  app.config.globalProperties.$http = axios
  app.config.globalProperties.$checkAppState = common
  // app.config.globalProperties.$http = PDF

  app.config.globalProperties.$httpRequestList = [];//  cancelToken中的cancel函数

  const version = '2.1.3'
  const Country = sessionStorage.getItem('area')
  const brower = sessionStorage.getItem('brower')
  const getOS = sessionStorage.getItem('getOS')
  installElementPlus(app)
  app.use(VueGtag, {
    appName: 'PDnob-web-static',
    config: {
      id: 'UA-238236071-1',
      params: {
        dimension1: version,
        dimension2: getOS,
        dimension3: brower,
        dimension4: Country
      }
    }
  })
  app.use(VueLazyload, {
    preLoad: 1.3, //预加载高度
    error: require('./assets/merge/loading.gif'), //错误展示图片
    loading: require('./assets/merge/loading.gif'), //加载等待图片
    attempt: 1 //尝试次数
  })
  app.use(store).use(vueI18n).use(router).use(VueCookies, { expireTimes: "7d", }).mount('#app')
}

