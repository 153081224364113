<template lang="">
  <div id="loading" class="custom-loading">
    <img src="../../assets/loading.png" alt="" class="circular">
    <p>{{$t(`message.Loading`)}}</p>
  </div>
  <img src="https://pdnob.passfab.com/images/pdnob-logo.svg" alt="" class="logo">
</template>
<script>

  export default {
    data() {
      return {
        convertingText: '',
        // timer: null,
        convertTime: ""
      }
    },
    watch: {},
    mounted() {
      this.convertingText = this.$t(`message.UploadFun.uploading[0]`)
      this.convertTime = new Date().getTime()
      let _this = this
      ////console.log("_this:", _this);

      // this.timer = setInterval(() => {
      //   if (_this.convertingText == _this.$t(`message.UploadFun.uploading[0]`)) {
      //     _this.convertingText = _this.$t(`message.UploadFun.uploading[1]`)
      //   } else {
      //     _this.convertingText = _this.$t(`message.UploadFun.uploading[0]`)
      //   }
      // }, 3000)
    },

    methods: {

    }
  }
</script>
<style lang="scss" scoped>
  .custom-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 44px);
    flex-direction: column;

    p {
      margin-top: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      color: rgba(29, 29, 31, 0.7);

    }
  }

  .custom-loading .circular {
    /* margin-right: 6px; */
    width: 120px;
    height: 120px;

    animation: loading-rotate 2s linear infinite;
  }

  .custom-loading .circular .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: var(--el-button-text-color);
    stroke-linecap: round;
  }

  .logo {
    position: absolute;
    width: 58px;
    height: 33.46px;
    top: calc(50% - 30px + 22px);
    left: calc(50% - 30px);
  }
</style>