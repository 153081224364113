<template>
  <div class="loginTop">
    <div class="left">
      <img src="../../assets/login/loginLeft.svg" alt="" v-if="$store.state.modalStatus == '2'">
      <img src="../../assets/login/loginLeft2.svg" alt="" v-else-if="$store.state.modalStatus == '1'">
      <img src="../../assets/login/loginLeft5.svg" alt="" v-else-if="$store.state.modalStatus == '3'">
      <!-- <img src="../../assets/login/loginLeft4.svg" alt="" v-else-if="showImg == 4"> -->
      <img src="../../assets/login/loginLeft3.svg" alt="" v-else>
    </div>
    <login v-if="$store.state.modalStatus == '1'" @goRegister="loginGoRegister" @goReset="loginGoReset"
      @loginResponseSuccess="loginResponseSuccess" @loginResponseError="loginResponseError" class="backdrop" />
    <Register v-else-if="$store.state.modalStatus == '2'" @goLogIn="Tologin"
      @loginResponseSuccess="loginResponseSuccess" @registerSuccess="registerSuccess" @registerError="registerError"
      class="backdrop"></Register>
    <Reset v-else-if="$store.state.modalStatus == '3'" @goLogIn="Tologin" @loginResponseSuccess="loginResponseSuccess"
      @changepasswordResponseSuccess="changepasswordResponseSuccess"
      @changepasswordResponseError="changepasswordResponseError" class="backdrop"></Reset>
    <div class="right" v-else-if="$store.state.modalStatus == '0'">
      <!-- <div class="right-sucess" v-if="showImg == 4">
        <p class="top-title">Login succeed!</p>
        <p class="mid-title">Congraduation! You will gain these priorities:</p>
      </div> -->
      <div class="right-sucess">
        <p class="top-title">Anmeldung</p>
        <p class="top-title">fehlgeschlagen!</p>
        <p class="mid-title">Your devices have exceeded the limitation, <span class="contactUs" @click="goPath">contact
            us</span> to
          change device.</p>
      </div>
      <div class="right-buttom" @click="closeDialog">Done</div>

    </div>
  </div>
</template>

<script>
  import Login from "./children/login";
  import Register from "./children/Register.vue";
  import Reset from "./children/Reset.vue";
  import axios from "axios";
  export default {
    name: "Signup",
    data() {
      return {
        formData: {
          UserName: "",
          UserPassword: "",
          Code: "",
          signInCount: 0,
          isShowPassword: false,
          isOk: false,
          flag: true,
          code: "",
        },
        showImg: 5,//展示图片

      }

    },
    components: {
      Login,
      Register,
      Reset,
    },
    mounted() {
      console.log("进来登录了");
    },
    methods: {
      //   // 登录去注册   0:啥框都不显示， 1： 登录， 2. 注册，  3. 重置密码
      loginGoRegister() {
        this.$store.commit("setModalStatus", "2");
      },
      goPath() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=ds")
      },
      // 登录去重置
      loginGoReset() {
        this.$store.commit("setModalStatus", "3");
      },
      refreshTab() {
        this.$store.dispatch("getUserInfo");
        this.$store.dispatch("getDeviceInfo");
        this.$store.dispatch("updateMenuList");
      },

      // 登录成功
      loginResponseSuccess(index, num) {
        console.log("name:1212", index, num);
        // 用户登录成功时统计一次
        if (index == 5) {
          this.$store.commit("setModalStatus", "0");
          this.showImg = 5
        } else {
          this.$message({
            message: "Login successfully!",
            type: "success",
            // duration: 900000,
          });
          let code = localStorage.getItem("authorization");
          console.log("code::::", code, localStorage.getItem('deviceId'));
          const config = {
            headers: {
              'Authorization': code,
              'device-id': localStorage.getItem('deviceId')
            }
          };
          // this.$http
          //   .get('/app/user/profile', config)
          axios.get('/app/user/profile', config)
            .then(async (res) => {
              console.log("进来了老弟");
              // res.data.expired_time = dateFormat(res.data.vip_expired_time)
              // res.data.count = res.data.total_count - res.data.used_count
              // // this.$store.state.userInfo = res.data
              // this.$store.commit('getUserInfo', res.data)
              // console.log("Pcmoment:", dateFormat(res.data.vip_expired_time));
              this.$store.commit('changeStateShowLogin', false)
              console.log("res.data.used_device_count:", res);
              console.log("res.data.used_device_count:", res.data.data);
              console.log("res.data.used_device_count:", res.data.data.used_device_count);
              if (!num) {
                console.log("这还进得来？");
                this.$gtag.event("sign_success", {
                  event_category: "Login_Register",
                  event_label: res.data.data.used_device_count,
                });
              }
              setTimeout(() => {
                window.location.reload()
              })
            })
            .catch(() => {
              console.log("断网了");
              this.isnetwork = true
            })
        }


      },

      // 登录失败
      loginResponseError() {
        // 用户登录失败时统计一次
        // event("Login_Fail", {
        //   event_category: "LogRegister",
        //   event_label: "None",
        // });
      },

      // 去登录
      Tologin() {
        this.$store.commit("setModalStatus", "1");
      },

      // 注册成功
      egisterSuccess() {
        // 用户登录失败时统计一次
        event("Register_Success", {
          event_category: "LogRegister",
          event_label: "None",
        });
        // loginResponseSuccess(data)
      },

      // 注册失败
      registerError() {
        // 用户注册失败时统计一次
        event("Register_Fail", {
          event_category: "LogRegister",
          event_label: "None",
        });
      },

      // 重置密码成功
      changepasswordResponseSuccess() {
        // 用户注册失败时统计一次
        // event("FindPassword_Success", {
        //   event_category: "LogRegister",
        //   event_label: "None",
        // });
        // Tologin();
      },

      // 重置密码失败
      changepasswordResponseError() {
        // event("FindPassword_Fail", {
        //   event_category: "LogRegister",
        //   event_label: "None",
        // });
      },
      registerSuccess() { },
      closeDialog() {
        this.$store.commit('changeStateShowLogin', false)
      }
    },
  };
</script>

<style lang="scss" scoped>
  .contactUs {
    color: #5452F6;
    cursor: pointer;

    &:hover {
      text-decoration: underline
    }
  }

  .loginTop {
    position: relative;
    display: flex;
    justify-content: center;
    width: 732px;
    min-height: 460px;
    max-height: 460px;
  }

  .left {
    min-width: 320px;
    min-height: 460px;
    /* background-image: url("../../assets/login/loginLeft.svg"); */
    background-repeat: no-repeat;
    background-size: cover;

    img {
      border-radius: 4px 0px 0 4px;
    }
  }

  .loginLogo {
    background-repeat: no-repeat;
    background-size: 30% 100%;
    height: 33px;
    margin-top: 60px;
  }

  .centerImg {
    background-image: url("../../assets/login/groupImg.png");
    background-repeat: no-repeat;
    background-size: 73% 100%;
    height: 584px;
    margin-left: 20%;
    margin-top: 7%;
  }

  .right {
    min-width: 412px;
    max-width: 412px;
    min-height: 460px;
    max-height: 460px;
    padding: 74px 50px 0 50px;

    .right-sucess {
      .top-title {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 14px;
        color: #1D1D1F;
      }

      .mid-title {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        color: rgba(29, 29, 31, 0.7);
      }
    }

    .right-buttom {
      position: absolute;
      width: 150px;
      height: 36px;
      bottom: 84px;
      background: #5452F6;
      border-radius: 2px;
      text-align: center;
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 36px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
</style>