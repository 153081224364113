<template lang="">
  <div class="secure-area">
    <div class="secure-are-content">
      <ul class="flexible flex-justify-between flex-wrap" v-if="pageName =='pdf-combiner'">
        <li class="cell">
          <img src="../assets/merge/combine-pdf.svg" alt="" />
          <h3>{{$t(`message.secure.h3.merge[0]`)}}</h3>
          <p>
            {{$t(`message.secure.p.merge[0]`)}}
          </p>
        </li>
        <li class="cell">
          <img src="../assets/merge/reorder-files.svg" alt="" />
          <h3>{{$t(`message.secure.h3.merge[1]`)}}</h3>
          <p>{{$t(`message.secure.p.merge[1]`)}}</p>
        </li>
        <li class="cell" style="margin-right: 0;">
          <img src="../assets/convert/safe-secure.svg" alt="">
          <h3>{{$t(`message.secure.h3.merge[2]`)}}</h3>
          <p>{{$t(`message.secure.p.merge[2]`)}} </p>
        </li>
      </ul>
      <ul class="flexible flex-justify-between flex-wrap" v-else-if="pageName =='compress-pdf'">
        <li class="cell">
          <img src="../assets/compress/compress-pdf.svg" alt="" />
          <h3>{{$t(`message.secure.h3.compress[0]`)}}</h3>
          <p>
            {{$t(`message.secure.p.compress[0]`)}}
          </p>
        </li>
        <li class="cell">
          <img src="../assets/convert/without-losing.svg" alt="" />
          <h3>{{$t(`message.secure.h3.compress[1]`)}}</h3>
          <p>{{$t(`message.secure.p.compress[1]`)}}</p>
        </li>
        <li class="cell" style="margin-right: 0;">
          <img src="../assets/convert/safe-secure.svg" alt="">
          <h3>{{$t(`message.secure.h3.compress[2]`)}}</h3>
          <p>{{$t(`message.secure.p.compress[2]`)}} </p>
        </li>
      </ul>
      <ul class="flexible flex-justify-between flex-wrap" v-else-if="pageName =='remove-watermark'">
        <li class="cell">
          <img src="../assets/removeWater/remove-watermarks.svg" alt="" />
          <h3>{{$t(`message.secure.h3.removeWater[0]`)}}</h3>
          <p>
            {{$t(`message.secure.p.removeWater[0]`)}}
          </p>
        </li>
        <li class="cell">
          <img src="../assets/removeWater/easy-use.svg" alt="" />
          <h3>{{$t(`message.secure.h3.removeWater[1]`)}}</h3>
          <p>{{$t(`message.secure.p.removeWater[1]`)}}</p>
        </li>
        <li class="cell" style="margin-right: 0;">
          <img src="../assets/convert/safe-secure.svg" alt="">
          <h3>{{$t(`message.secure.h3.removeWater[2]`)}}</h3>
          <p>{{$t(`message.secure.p.removeWater[2]`)}} </p>
        </li>
      </ul>
      <ul class="flexible flex-justify-between flex-wrap" v-else>
        <li class="cell">
          <img src="../assets/convert/pdf-convert.svg" alt="" />
          <h3>{{$t(`message.secure.h3.convert[0]`)}}</h3>
          <p>
            {{$t(`message.secure.p.convert[0]`)}}
          </p>
        </li>
        <li class="cell">
          <img src="../assets/convert/without-losing.svg" alt="" />
          <h3>{{$t(`message.secure.h3.convert[1]`)}}</h3>
          <p>{{$t(`message.secure.p.convert[1]`)}}</p>
        </li>
        <li class="cell" style="margin-right: 0;">
          <img src="../assets/convert/safe-secure.svg" alt="">
          <h3>{{$t(`message.secure.h3.convert[2]`)}}</h3>
          <p>{{$t(`message.secure.p.convert[2]`)}} </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        pageName: ""
      }
    },
    mounted() { },
    watch: {
      $route(to) {
        ////console.log("pageName1111:", this.pageName);
        if (to.fullPath.indexOf('pdf-combiner') > 0) {
          this.pageName = "pdf-combiner"
        } else if (to.fullPath.indexOf('compress-pdf') > 0) {
          this.pageName = "compress-pdf"
        } else if (to.fullPath.indexOf('remove-watermark') > 0) {
          this.pageName = "remove-watermark"
        } else {
          this.pageName = ""
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .secure-area {
    padding: 117px 0 40px 0;

    .secure-are-content {
      max-width: 1224px;
      margin: 0 auto;

      ul {
        padding: 0;
      }

      .cell {
        max-width: 360px;
        margin-top: 40px;
        text-align: left;

        margin-right: 72px;

        h3 {
          font-size: 18px;
          font-weight: 700;
          color: #3d454d;
          margin-top: 16px;
        }

        p {
          text-align: left;
          margin-top: 16px;
          color: #525c66;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
</style>