<template lang="">
  <div class="operation-area">
    <div class="operation-are-content" v-if="pageName =='pdf-combiner'">
      <img v-lazy="imgList[0].src" alt="">
      <div class="operation-right">
        <h2>{{$t(`message.operation.title.merge`)}}</h2>
        <ul class="flex-ul">
          <li class="cell" style="margin-top: 0;">
            <img src="../assets/operation/1.svg" alt="" />
            <span>{{$t(`message.operation.h3.merge[0]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/2.svg" alt="" />
            <span>{{$t(`message.operation.h3.merge[1]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/3.svg" alt="" />
            <span>{{$t(`message.operation.h3.merge[2]`)}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="operation-are-content" v-else-if="pageName =='compress-pdf'">
      <img v-lazy="imgList[1].src" alt="">
      <div class="operation-right">
        <h2>{{$t(`message.operation.title.compress`)}}</h2>
        <ul class="flex-ul">
          <li class="cell" style="margin-top: 0;">
            <img src="../assets/operation/1.svg" alt="" />
            <span>{{$t(`message.operation.h3.compress[0]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/2.svg" alt="" />
            <span>{{$t(`message.operation.h3.compress[1]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/3.svg" alt="" />
            <span>{{$t(`message.operation.h3.compress[2]`)}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="operation-are-content" v-else-if="pageName =='remove-watermark'">
      <img v-lazy="imgList[3].src" alt="">
      <div class="operation-right">
        <h2>{{$t(`message.operation.title.removeWater`)}}</h2>
        <ul class="flex-ul">
          <li class="cell" style="margin-top: 0;">
            <img src="../assets/operation/1.svg" alt="" />
            <span>{{$t(`message.operation.h3.removeWater[0]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/2.svg" alt="" />
            <span>{{$t(`message.operation.h3.removeWater[1]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/3.svg" alt="" />
            <span>{{$t(`message.operation.h3.removeWater[2]`)}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="operation-are-content" v-else>
      <img v-lazy="imgList[2].src" alt="">
      <div class="operation-right">
        <h2>{{$t(`message.operation.title.convert`)}}</h2>
        <ul class="flex-ul">
          <li class="cell" style="margin-top: 0;">
            <img src="../assets/operation/1.svg" alt="" />
            <span>{{$t(`message.operation.h3.convert[0]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/2.svg" alt="" />
            <span>{{$t(`message.operation.h3.convert[1]`)}}</span>
          </li>
          <li class="cell">
            <img src="../assets/operation/3.svg" alt="" />
            <span>{{$t(`message.operation.h3.convert[2]`)}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        footType: '',
        pageName: "",
        imgList: [
          {
            src: require("../assets/operation/mergeOperation.png")
          },
          {
            src: require("../assets/operation/compress.png")
          },
          {
            src: require("../assets/operation/convert.png")
          },
          {
            src: require("../assets/operation/removeWatermark.png")
          }
        ]
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.footType = this.$store.state.local
      })
      // this.pageName = this.$route
    },
    watch: {
      $route(to) {
        ////console.log("pageName:", this.pageName);

        if (to.fullPath.indexOf('pdf-combiner') > 0) {
          this.pageName = "pdf-combiner"
        } else if (to.fullPath.indexOf('compress-pdf') > 0) {
          this.pageName = "compress-pdf"
        } else if (to.fullPath.indexOf('remove-watermark') > 0) {
          this.pageName = "remove-watermark"
        } else {
          this.pageName = ""
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .operation-area {
    /* padding: 120px 0; */
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSI1NzYiIHZpZXdCb3g9IjAgMCAxOTIwIDU3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE5MjAiIGhlaWdodD0iNTc2IiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNjA4MF83NTc5KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzYwODBfNzU3OSIgeDE9Ijk2MCIgeTE9IjAiIHgyPSI5NjAiIHkyPSI1NzYiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Y1RjdGRCIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGNUY3RkQiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
    background-size: cover;

    .operation-are-content {
      display: flex;
      max-width: 1224px;
      height: 576px;
      margin: 0 auto;
      align-items: center;

      .operation-right {
        margin-left: 144px;

        h2 {
          text-align: left;
          font-size: 32px !important;
        }
      }

      img {
        max-width: 456px;
        max-height: 320px;
      }

      .flex-ul {
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        margin-top: 48px !important;
      }

      .cell {
        /* max-width: 280px; */
        margin-top: 25px;
        display: flex;

        img {
          vertical-align: middle;
        }

        span {
          font-size: 18px;
          color: #525c66;
          margin-left: 8px;
          font-weight: 400;
          /* margin-top: 32px;
          min-height: 66px; */
        }
      }
    }
  }
</style>