import { event, customMap } from "vue-gtag";
import store from '../store'
export const gaEvent = (eventName, eventObj) => {
  customMap({
    dimension1: "dimension1",
    dimension2: "dimension2",
    dimension3: "dimension3",
    dimension4: "dimension4",
  });
  const version = '1.0.1.1'
  const Country = sessionStorage.getItem('area')
  const userIdentity = store.state.userIdentity
  const userInfoPc = localStorage.getItem('userInfoPc')

  const eventConfig = {
    dimension1: store.state.userIdentity ? store.state.userIdentity : 'null',
    dimension2: version,
    dimension3: userInfoPc ? userInfoPc : 'null',
    dimension4: Country ? Country : 'null',
  };
  const eventPayload = {
    ...eventObj,
    ...eventConfig,
  };
  event(eventName, eventPayload);
};
