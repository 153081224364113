// 点击除该指令绑定的元素之外的区域触发
export const clickOutside = (app) => {
  app.directive("click-outside", {
    mounted(el, binding, node) {
      const eventHandler = function eventHandler(e) {
        // 如果当前点击的dom包含在指定作用的dom节点上，则不做任何处理
        if (el.contains(e.target)) {
          return false;
        }
        // 如果绑定的参数是函数，正常情况也应该是函数，执行
        if (binding.value && typeof binding.value === "function") {
          binding.value(e);
        }
      };
      // 用于销毁前注销事件监听
      el.__click_outside__ = eventHandler;
      document.addEventListener("click", eventHandler);
    },
    unmounted(el, binding, node) {
      // 移除事件监听
      document.removeEventListener("click", el.__click_outside__);
      // 删除无用属性
      delete el.__click_outside__;
    },
  });
};
