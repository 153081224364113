export default {
  LTrim(str) {
    var i;
    for (i = 0; i < str.length; i++) {
      if (str.charAt(i) != " ")
        break;
    }
    str = str.substring(i, str.length);
    return str;
  },
  RTrim(str) {
    var i;
    for (i = str.length - 1; i >= 0; i--) {
      if (str.charAt(i) != " ")
        break;
    }
    str = str.substring(0, i + 1);
    return str;
  },
}