import moment from "moment";

/*
 * 对时间进行格式化
 * @param value : 需要进行格式化的时间值 单位： s
 * @param format : 格式化的规则
 */
function dateFormat(value = new Date(), format = "YYYY-MM-DD") {
  if (!isNaN(value)) {
    value = parseInt(value) * 1000;
  }
  // console.log(moment(1666596045000));
  // console.log(moment(1666596045000).utcOffset(2).format("YYYY-MM-DD HH:mm"));
  // console.log(moment(1666596045000).format("YYYY-MM-DD HH:mm"));
  // console.log("==========", moment("2022-10-24 21:20").format("X"));
  // console.log(moment(1666596000000).format("YYYY-MM-DD HH:mm"));

  // return moment(value).utcOffset(6).format(format);
  return moment(value).format(format);
};
export { dateFormat }