export default {
	message: {
		location:"https://www.passfab.tw/pdnob.html",
		new:"NEW",
		new1:"即將推出",
		ogtitle:"請到專業網站在線免費處理PDF文件：https://www.passfab.tw/pdnob.html 來自PassFab.tw",
		ogdescription:"PDNob是一款簡單可靠的PDF轉換工具，12種PDF格式轉換功能，還有分割PDF、合併PDF等文件處理功能。一起來PDNob探索更多功能吧。",
		init:{
			text:["選擇要使用的工具","選擇要處理的文件","設置處理的模式","開始"]
		},
		logo:"http://www.passfab.com/images/pdf-convert/pdf-logo.svg",
		wating:"正在加載頁面，請稍候",
		popus:"加載所有頁面後有效",
		Navigation: {
			pdf: {
				title: "PDF工具集",
				info: ["PDF轉換成Word", "PDF轉換成Excel", "PDF轉換成PPT", "PDF轉換成TEXT", "PDF轉換成JPG", "PDF轉換成HTML",
					"Word轉換成PDF", "Excel轉換成PDF", "PPT轉換成PDF", "TEXT轉換成PDF", "圖片轉換成PDF", "HTML轉換成PDF",
					"壓縮PDF", "合併PDF", "分割PDF", "PDF加密", "PDF頁面刪除", "旋轉PDF",
					"添加水印", "PDF圖片提取", "PDF頁面提取"
				]
			},
			img: {
				title: "圖片工具集",
				info: []
			},
			Other: {
				title: "其他工具集",
				info: []
			},
		},
		FunList: ["PDF轉換器", "分割PDF", "Merge PDF", "添加水印", "壓縮PDF", "旋轉PDF",
			"加密PDF", "PDF頁面刪除", "PDF圖片提取", "PDF頁面提取"
		],
		FunOperat: {
			splitPDF: {
				Operat: "分割PDF",
				Operat1: "提取頁面",
				Split: {
					text: ["分割", "在每一頁後面分割", "自定義範圍"],
					Range: ["範圍", "添加範圍", "合併成一個PDF","到"]
				},
				Extract: {
					text: ["提取", "提取所有頁面", "從所選頁面中提取"],
					Page: ["頁碼", "輸入頁碼", "例如：1,2-3,6-8"]
				},
				tip: "您最多可以選擇5個範圍將PDF拆分為5個PDF。例如，在範圍1中設置第1頁到第4頁，即，將此PDF文件的第1頁到第4頁拆分為新的PDF。"
			},
			mergePDF: {
				Operat: "合併PDF",
				add: {
					text: ["文件", "添加文件", "拖動文件改變順序。","你不能添加超過4個文件。"]
				}
			},
			addWater: {
				Operat: "應用",
				text: {
					text: ["文本", "輸入文本", "尺寸", "透明度", "位置", "旋轉", "平鋪顯示"]
				},
				img: {
					text: ["圖片", "添加圖片"]
				}
			},
			compressPDF: {
				Operat: "壓縮PDF",
				Compression: {
					text: ["壓縮模式", "清晰優先", "縮小優先"]
				}
			},
			rotatePDF: {
				Operat: "旋轉PDF",
				mess:"正在加載圖片，請稍後。",
				Rotate: {
					text: ["旋轉所有", "左", "右"]
				}
			},
			protectPDF: {
				Operat: "加密PDF",
				protect: {
					text: ["密碼", "輸入你的密碼", "重複密碼", "重複你的密碼"],
					tip:["請填寫兩次密碼以保護PDF文件。","密碼不能為空。","請輸入與上述相同的密碼。"]
				}
			},
			delatePDF: {
				Operat: "刪除頁面",
			},
			extractIMG: {
				Operat: "提取圖片",
				img: {
					text: ["圖片格式", "提取模式", "從所有頁面提取", "从所选页面中提取",
						"頁碼", "輸入頁碼", "例如：1,2-3,6-8"
					]
				}
			}

		},
		selectFile: ["選擇文件", "或者拖拽文件到這裡", "把HTML轉換成PDF","輸入 URL 以添加 HTML文件。"],
		SelectFun: {
			title: "選擇轉換功能",
			Fun: ["轉換成Word", "轉換成JPG", "轉換成Excel", "轉換成TEXT", "轉換成PPT",
				"轉換成HTML", "轉換成PDF"
			],
			operation: []
		},
		fileTopdf: {
			button: "轉換成PDF",
            JpgToPdf:{
				text: ["圖片", "添加圖片","你不能添加超過4張圖片。","拖動圖片改變順序。","轉換成一個PDF"]
			}
		},
		PdfTofile: {
			button: ["轉換成Excel", "轉換成HTML", "轉換成圖片", "轉換成PPT", "轉換成TEXT",
				"轉換成Word"
			],
			htmlToPDF: [
				"正在進入預覽界面，請耐心等待。",
				"網站地址",
				"頁面尺寸",
				"不能為空。",
				"您輸入的URL無效。請重新輸入正確的URL。"
			],
			pdftoimg: "去提取圖像"
		},
		convertTip: ["正在轉換文件，請耐心等待。",
			"正在分割文件，請耐心等待。",
			"正在合併文件，請耐心等待。",
			"正在為文件添加水印，請耐心等待。",
			"正在壓縮文件，請耐心等待。",
			"正在旋轉頁面，請耐心等待。",
			"正在加密文件，請耐心等待。",
			"正在刪除文件中的頁面，請耐心等待",
			"正在提取文件中的圖片，請耐心等待。",
			"正在提取文件中的頁面，請耐心等待。"
		],
		successText: ["您的文件已經被轉換成Word文件。",
			"您的文件已經被轉換成Excel文件。",
			"您的文件已經被轉換成PPT文件。",
			"您的文件已經被轉換成TEXT文件。",
			"您的文件已經被轉換成PDF文件。",
			"您的文件已經被轉換成JPG文件。",
			"您的文件已經被轉換成HTML文件。",
			"已成功分割您的文件。",
			"已成功合併您的文件。",
			"已成功為您的文件添加水印。",
			"已成功壓縮您的文件",
			"已成功旋轉您的文件頁面。",
			"已成功加密您的文件。",
			"已成功刪除頁面。",
			"已成功提取您的文件圖片。",
			"已成功提取您的文件頁面。"
		],
		convertConfirm: {
			error: "轉換失敗！",
			title: "您想再次嘗試轉換嗎？",
			confirmButtonText: "再試一次",
			cancelButtonText: "取消",
		},
		commonTip: {
			pop: [{
				"popConverFailTit": "轉換失敗！",
				"popConverFailText": "您想再次嘗試轉換嗎？",
			}, {
				"popConverFailTit": "合併失敗！",
				"popConverFailText": "您想再次嘗試合併嗎？",
			}, {
				"popConverFailTit": "分割失敗！",
				"popConverFailText": "您想再次嘗試分割嗎？",
			}, {
				"popConverFailTit": "加密失敗！",
				"popConverFailText": "您想再次嘗試加密嗎？",
			}, {
				"popConverFailTit": "壓縮失敗！",
				"popConverFailText": "您想再次嘗試壓縮嗎？",
			}, {
				"popConverFailTit": "刪除頁面失敗！",
				"popConverFailText": "您想再次嘗試刪除頁面嗎？",
			}, {
				"popConverFailTit": "旋轉頁面失敗！",
				"popConverFailText": "您想再次嘗試旋轉頁面嗎？",
			}, {
				"popConverFailTit": "提取頁面失敗！",
				"popConverFailText": "您想再次嘗試提取頁面嗎？",
			}, {
				"popConverFailTit": "提取圖片失敗！",
				"popConverFailText": "您想再次嘗試提取圖片嗎？",
			}, {
				"popConverFailTit": "增加水印失敗！",
				"popConverFailText": "您想再次嘗試添加水印嗎？",
			}],
			alertTip: [{
					poptit: "只能轉換一個檔案,",
					poptext: "請選擇一個檔案。",
					confirmButtonText: "確認"
					
				}, {
					poptit: "此文件格式不受支持！",
					poptext: "此功能不支持此類型的文件。請上傳相應類型的文件。",
					confirmButtonText: "確認"
				}, {
					poptit: "您所上傳的文件大小超出限制，因此無法上傳！",
					poptext: "上傳文件最大的大小為10MB。",
					confirmButtonText: "確認"
				}, {
					poptit: "無法讀取您的文件，",
					poptext: "請檢查您的文件是否已損壞/加密。",
					confirmButtonText: "確認"
				}, {
					poptit: "下載失敗！",
					poptext: "你想要再次嘗試下載嗎？",
					confirmButtonText: '再試一次',
					cancelButtonText: '取消',
				}, {
					poptit: "此圖檔格式不受支持！",
					poptext: "此功能不支持此格式的圖檔。請上傳格式為JPG、PNG或BMP的圖檔。",
					confirmButtonText: "確認"
				}, {
					poptit: "下載失敗！",
					poptext: "一個多小時文件已過期，請重試",
					confirmButtonText: '再試一次',
					cancelButtonText: '取消',
				}, {
					poptit: "轉換失敗",
					poptext: "某些原因導致檔案轉換失敗。",
					poptext1: "是否要再次嘗試轉換？",
					cancelButtonText: "確認",
					confirmButtonText: "取消"
				}, {
					poptext: "離開此頁面，保留文件紀錄，但當前的操作將不保留。",
					confirmButtonText: "確認",
					cancelButtonText: "取消"
				}, {
					poptext: "離開此頁面，文件記錄將不保留。",
					confirmButtonText: "確認",
					cancelButtonText: "取消"
				}, {
					poptit: "您的文件還沒下載，",
					poptext: "確定要離開此頁面嗎？",
					confirmButtonText: "確認",
					cancelButtonText: "取消"
				}, {
					poptext: "服務器異常，您的文件無法上傳！",
					confirmButtonText: "再試一次",
					cancelButtonText: "取消"
				}, {
					poptit: "預覽失敗！",
					poptext: "找不到您嘗試訪問的網頁或該網頁存在連接問題。請檢查URL是否正確寫入，然後重試。",
					confirmButtonText: "確認",
				},{
					poptit: "轉換失敗！",
					poptext: "您想再次嘗試轉換嗎？",
					confirmButtonText: "再試一次",
					cancelButtonText: "取消",
				}
				


			]
		},
		downloadPag: {
			tip: "我們將為您保存1個小時，請盡快下載，感謝您的使用。",
		},
		download: "下載",
		Downloading: "下載中",
		Restart: "重新開始",
		back: "返回首頁",
        funText:{
			title:"什麼是PassFab PDNob？",
			h3:["卓越的免費PDF工具集","出色的文檔處理質量","簡單可靠的工具","適用所有操作系統","自動刪除","貼心服務"],
			p:["功能十分強大，提供12種PDF格式轉換，還有分割、合併等文件處理的功能。",
				"所有處理過的文檔都是高質量的，並且沒有水印。",
				"用戶友好的界面，並使用HTTPS（超文本傳輸協議安全）技術來保護文檔在處理過程中的安全。",
				"軟件在瀏覽器高效運行，支持所有操作系統，Chrome、Firefox 和 Opera 等多種瀏覽器。",
				"一小時後，我們的服務器將自動永久刪除您的所有文件。",
				"在文件處理的過程中遇到疑惑，可到幫助中心尋找教程或者聯繫客服"

		]
		},
		stars:{
			text:["請單擊星星給出您的評分","票數","分享給您的朋友","提交"]
		}

	},
}
