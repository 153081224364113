import axios from "axios";
import store from '../store'
// const reqUrl = 'http://149.28.247.138:8091'
// const wsReqUrl = 'ws://149.28.247.138:8091'  //第一测试

// const reqUrl = 'https://appsrv.passfab.com'
// const wsReqUrl = 'wss://appsrv.passfab.com'  //pdnob正式

//  const reqUrl =  'http://155.138.240.205:8091'
//  const wsReqUrl =  'ws://155.138.240.205:8091' //第二测试


// const reqUrl =  'https://45.63.62.238:8091'   //迁移地址域名
// const wsReqUrl =  'ws://45.63.62.238:8091'

// const reqUrl = 'https://pdnob-test-api.afirstsoft.cn'
// const wsReqUrl = 'wss://pdnob-test-api.afirstsoft.cn'  //pdnob测试（发布）

const reqUrl = process.env.NODE_ENV === 'production' ? 'https://appsrv.passfab.com' : 'https://pdnob-test-api.afirstsoft.cn'
const wsReqUrl = process.env.NODE_ENV === 'production' ? 'wss://appsrv.passfab.com' : 'wss://pdnob-test-api.afirstsoft.cn'

// const reqUrl = 'http://106.55.63.141:8081'
// const wsReqUrl = 'ws://106.55.63.141:8081'

// const reqUrl = 'http://45.76.187.48:8091'
// const wsReqUrl = 'ws://45.76.187.48:8091'

console.log(process.env.NODE_ENV);
axios.defaults.baseURL = reqUrl



//if obj's format not FormData ,change format to FormData
function paramsToFormData(obj) {
	////console.log(Object.prototype.toString.call(obj) == '[object FormData]')
	if (Object.prototype.toString.call(obj) != '[object FormData]') {
		const formData = new FormData();
		Object.keys(obj).forEach((key) => {
			if (obj[key] instanceof Array) {
				obj[key].forEach((item) => {
					formData.append(key, item);
				});

				return;
			}

			formData.append(key, obj[key]);
		});
		return formData;
	} else {
		return obj;
	}
}

//post请求头
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.timeout = 5 * 60000;

if (navigator.userAgent.indexOf('pdfName') > 0) {
	axios.defaults.headers["from-where"] = "pc";
	axios.defaults.headers["time-offset"] = new Date().getTimezoneOffset();
	// axios.defaults.headers["Authorization"] = localStorage.getItem('token') ? localStorage.getItem('token') : '';
	axios.defaults.headers["Authorization"] = store.state.token ? store.state.token : '';
	axios.defaults.headers["device-id"] = localStorage.getItem('deviceId');
	axios.defaults.timeout = 1000 * 60 * 60 * 2;
}

//设置超时

axios.interceptors.request.use(
	config => {
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		// console.log("response11:", response);
		if (response.status == 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {
		// console.log("error11:", error);

		// console.log(error.response);
		// console.log("40----------------33:", error);
		return Promise.reject(error);
		// handleApiFail(error.response)

	}
);





export default {
	post(url, data) {
		return new Promise((resolve, reject) => {
			////console.log(paramsToFormData(data))
			axios({
				method: 'post',
				url,
				data: paramsToFormData(data)

			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					console.log("40211111111----------3:", err);
					reject(err)
				});
		})
	},
	postRotate(url, data) {
		axios.defaults.headers.post["Accept"] = 'application/json',
			axios.defaults.headers.post["Content-Type"] = 'application/json'
		return new Promise((resolve, reject) => {
			////console.log(paramsToFormData(data))
			axios({
				method: 'post',
				url,
				data: data

			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					// console.log("401311111:", err);
					reject(err)
				});
		})
	},
	postGetToken(url) {
		axios.defaults.headers.post["Content-Type"] = 'application/x-www-form-urlencoded'
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url,
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					// console.log("403:", err);
					reject(err)
				});
		})
	},

	get(url, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url,
				params: data,
			})
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	ws(token, reqNumber, typeZip) {
		return new Promise((resolve, reject) => {
			var ws = new WebSocket(wsReqUrl + "/app/progressbar");
			ws.onopen = function () {
				////console.log('conected')
				if (!typeZip) {
					let mes = {
						token: '' + token,
						number: reqNumber
					}
					ws.send(JSON.stringify(mes));
				} else if (typeZip == 'pdfZip') {
					let mes = {
						token: '' + token,
						number: reqNumber,
						type: 'pdf_zip'
					}
					ws.send(JSON.stringify(mes));
				} else {
					let mes = {
						token: '' + token,
						number: reqNumber,
						type: 'zip'
					}
					ws.send(JSON.stringify(mes));
				}
			};
			ws.onmessage = function (res) {
				////console.log(JSON.parse(res.data).data.progress)
				if (JSON.parse(res.data).data.progress == 100) {
					ws.onclose
					resolve(JSON.parse(res.data))
					////console.log(status)
				}
				if (JSON.parse(res.data).data.status != '1' && JSON.parse(res.data).data.status != '2') {
					reject(JSON.parse(res.data))
				}
			};
			ws.onclose = function () {
				////console.log("Connection closed.");
			};
			ws.onerror = function (err) {
				////console.log('ws错误' + err)
				reject(err)
			}
		})
	}
};
