import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {
		funName: 'convert',
		fileType: '',
		isPDF: false,
		appState: 'start',
		pdfToken: '',
		token: '',
		downUrl: '',
		appFileList: [],
		local: "",
		uploadingFlag: false,
		isDown: 0, //0默认没有 1已下载 2没有下载
		// locationUrl: process.env.NODE_ENV === 'location' ? process.env.WEBPACK_DEV_SERVER_URL : (process.env.NODE_ENV === 'development' ? 'https://pdf-test.afirstsoft.cn/' : 'https://pdf.pdnob.com/')
		locationUrl: window.location.origin,
		showLogin: false, //登录弹窗
		modalStatus: "1", // 0:啥框都不显示， 1： 登录， 2. 注册，  3. 重置密码
		loginStatus: false, // false : 未登录  true ： 已登录
		isShowPur: false,//是否显示购买弹窗
		loginTime: 0, // 登录的响应时长
		isLoginExpired: false, // 是否登录失效
		expired: false, // 判断用户权限是否过期
		deviceId: "",//设备唯一id
		locationHref: '',//目前的链接
		showHome: false,//是否显示返回首页
		currentPage: "home",//当前页面
		processTime: 0,//转化的时间
		navName: '',
		userIdentity: 'visitor',//用户身份
		userInfoPc: '',//电脑设备信息
		optCount: {
			remaining: 0,
			total: 0
		},//剩余次数
		// 个人信息
		userInfo: {
			email: ''
		},
		// 权限信息
		subscripts: {
			begins_at: "",
			expires_at: "",
			total_device_num: "",
			effective_device_num: "",
		},
		menuIndex: 0,//菜单的下标
		menuListAll: [ //所有菜单
			{
				title: 'Popular',
				data: [
					{
						img: require('../assets/convert/PTW.svg'),
						name: 'PDF to Word',
						path: '/pc-convert?from_toplist&to_word'
					},
					{
						img: require('../assets/convert/WTP.svg'),
						name: 'Word to PDF',
						path: '/pc-convert?from_toplist&from_other_word'
					},
					{
						img: require('../assets/merge/merge.svg'),
						name: 'Merge PDF',
						path: '/pc-pdf-combiner?from_funlist'
					},
					{
						img: require('../assets/compress/compress.svg'),
						name: 'Compress PDF',
						path: '/pc-compress-pdf?from_funlist'
					},
					{
						img: require('../assets/removeWater/removeWater.svg'),
						name: 'Remove Watermark',
						path: '/pc-remove-watermark?from_funlist',
						new: require('../assets/homePage/new.svg')
					}
				]
			},
			{
				title: 'Convert from PDF',
				data: [
					{
						img: require('../assets/convert/PTW.svg'),
						path: '/pc-convert?from_toplist&to_word',
						name: 'PDF to Word'
					},
					{
						img: require('../assets/convert/PTJ.svg'),
						path: '/pc-convert?from_toplist&to_jpg',
						name: 'PDF to JPG'
					},
					{
						img: require('../assets/convert/PTE.svg'),
						path: '/pc-convert?from_toplist&to_excel',
						name: 'PDF to Excel'
					},
					{
						img: require('../assets/convert/PDTPP.svg'),
						path: '/pc-convert?from_toplist&to_ppt',
						name: 'PDF to PPT'
					},
					{
						img: require('../assets/convert/PTT.svg'),
						path: '/pc-convert?from_toplist&to_text',
						name: 'PDF to TXT'
					},
					{
						img: require('../assets/convert/PTH.svg'),
						path: '/pc-convert?from_toplist&to_html',
						name: 'PDF to HTML'
					}
				]
			},
			{
				title: 'Convert to PDF',
				data: [
					{
						img: require('../assets/convert/WTP.svg'),
						path: '/pc-convert?from_toplist&from_other_word',
						name: 'Word to PDF'
					},
					{
						img: require('../assets/convert/ITP.svg'),
						path: '/pc-convert?from_toplist&from_other_jpg',
						name: 'Images to PDF'
					},
					{
						img: require('../assets/convert/ETP.svg'),
						path: '/pc-convert?from_toplist&from_other_excel',
						name: 'Excel to PDF'
					},
					{
						img: require('../assets/convert/PDTPP.svg'),
						path: '/pc-convert?from_toplist&from_other_ppt',
						name: 'PPT to PDF'
					},
					{
						img: require('../assets/convert/TTP.svg'),
						path: '/pc-convert?from_toplist&from_other_text',
						name: 'TXT to PDF'
					},
				]
			},
			{
				title: 'Optimize PDF',
				data: [
					{
						img: require('../assets/merge/merge.svg'),
						path: '/pc-pdf-combiner?from_funlist',
						name: 'Merge PDF'
					},
					{
						img: require('../assets/compress/compress.svg'),
						path: '/pc-compress-pdf?from_funlist',
						name: 'Compress PDF'
					},
					{
						img: require('../assets/removeWater/removeWater.svg'),
						path: '/pc-remove-watermark?from_funlist',
						name: 'Remove Watermark',
						new: require('../assets/homePage/new.svg')
					},
				]
			}
		],
		downloadPdfImg: ""//下载页的缩略图
	},
	mutations: {
		//修改转化时间
		changeProcessTime(state, data) {
			state.processTime = data
		},
		//是否出现购买弹窗
		changeIsShowPur(state, data) {
			state.isShowPur = data
		},
		//电脑设备信息
		changeUserInfoPc(state, data) {
			state.userInfoPc = data
		},
		changeUserIdentity(state, data) {
			state.userIdentity = data
		},
		//修改下载页的缩略图
		changeDownloadPdfImg(state, data) {
			state.downloadPdfImg = data
		},
		//更改当前页面
		changeCurrentPage(state, data) {
			console.log("该页面了？");
			state.currentPage = data
		},
		changenavName(state, data) {
			state.navName = data
		},
		//是否出现首页按钮
		changeShowHome(state, data) {
			state.showHome = data
		},
		//修改菜单当前下标
		changeStateMenuIndex(state, targetFunName) {
			state.menuIndex = targetFunName
			console.log("state.menuIndex:", state.menuIndex)
		},
		//修改当前的链接
		changeStateLocationHref(state, targetFunName) {
			state.locationHref = targetFunName
			console.log("state.locationHref:", state.locationHref)
		},
		//登录
		changeStateShowLogin(state, targetFunName) {
			state.showLogin = targetFunName
			console.log("state.showLogin:", state.showLogin)
		},
		//修改剩余次数
		changeOptCount(state, targetFunName) {
			state.optCount = targetFunName
		},
		changeStateFunName(state, targetFunName) {
			state.funName = targetFunName
		},
		//文件类型
		changeFileType(state, targetType) {
			state.fileType = targetType
		},
		//操作状态
		changeAppState(state, targetState) {
			state.appState = targetState;
			console.log("changeAppState:", state.appState);
		},
		changePdfToken(state, pdfToken) {
			state.pdfToken = pdfToken
		},
		//token
		changeToken(state, Token) {
			state.token = Token
		},
		//下载地址
		changeDownUrl(state, currentDownUrl) {
			state.downUrl = currentDownUrl
		},
		changeAppFileList(state, fileList) {
			state.appFileList = fileList
		},
		checkPDF(state, flag) {
			state.isPDF = flag
		},
		checkLocal(state, local) {
			state.local = local
		},
		changeDown(state, flag) {
			state.isDown = flag
		},
		changeUploadingFlag(state, flag) {
			state.uploadingFlag = flag
		},
		changeMenuLit(state, flag) {
			state.menuList = flag
		},
		// 登录状态
		setLoginStatus(state, status) {
			state.loginStatus = status;
		},
		// 获取用户信息
		getUserInfo(state, data) {
			state.userInfo = data;
		},
		// 保存权限信息
		setSubscripts(state, data) {
			state.subscripts = data;
		},
		// 登录类型
		setModalStatus(state, status) {
			state.modalStatus = status;
			console.log("state.modalStatus:", state.modalStatus);
		},
		// 登录验证
		setIsLoginExpired(state, status) {
			state.isLoginExpired = status;
		},
		// 登录验证
		setDeviceId(state, status) {
			state.deviceId = status;
		},
	},
	actions: {
	},
	plugins: [
		createPersistedState({
			paths: ['locationUrl', 'showLogin', 'modalStatus', 'loginStatus', 'loginTime', 'isLoginExpired', 'expired', 'deviceId',
				'locationHref', 'showHome', 'optCount', 'subscripts', 'userInfo', 'menuIndex', 'token', 'currentPage', 'navName', 'userIdentity', 'userInfoPc']
		}),
	]
})