<template lang="">
  <div class="purchase">
    <div class="close">
      <img src="../../assets/purchase/close.svg" alt="" @click="close" style="cursor: pointer;">
    </div>
    <p class="text">
      {{text}}
    </p>
    <div class="bottomDiv">
      <div class="item first">Download the software and you can enjoy more service</div>
      <div class="item other"><img src="../../assets/purchase/right.svg" alt=""><span>Batch file processing</span></div>
      <div class="item other"><img src="../../assets/purchase/right.svg" alt=""><span>More file capacity</span></div>
      <div class="item other"><img src="../../assets/purchase/right.svg" alt=""><span>Professional technical
          support</span>
      </div>
      <div class="buttom" @click="download">
        Download
      </div>
    </div>

  </div>

</template>
<script>

  export default {
    data() {
      return {
        convertingText: '',
        // timer: null,
        convertTime: "",
        text: 'The size or number of the file exceeds the limit, please download the software if you want to use it.'
      }
    },
    watch: {},
    mounted() {
    },

    methods: {
      close() {
        this.$store.commit('changeIsShowPur', false)
      },
      download() {
        window.open('https://download.pdnob.com/go/pdnob-pdf_5808.exe')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .purchase {
    width: 440px;
    height: 374px;
    background: #EEF5FF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 223px;
    left: 50%;
    /* left: 740px; */
    /* transform: calc(translate(50%) + 220px); */
    /* transform: translate(calc(50% + 220px)); */
    transform: translate(-50%);
    padding: 8px 20px 0 20px;
    z-index: 999;
    /* position: relative; */

    .close {
      /* position: absoulte; */
      width: 412px;
      text-align: right;
      margin-bottom: 8px;
    }

    .text {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(29, 29, 31, 0.7);
      margin-bottom: 16px;
    }

    .bottomDiv {
      width: 400px;
      height: 218px;
      background: linear-gradient(180deg, #A5A4FF 0%, #5452F6 100%);
      box-shadow: inset 0px 2px 4px #D7D7FF;
      border-radius: 4px;
      padding-left: 20px;
      padding-right: 16px;

      .item {
        height: 40px;
        padding-top: 14px;
        border-bottom: 1px solid rgba(255, 255, 255, .05);

        img {
          margin-right: 8px;
          margin-top: -3px;
        }
      }

      .first {
        font-family: 'Microsoft YaHei UI';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #FFFFFF;
      }

      .other {
        font-family: 'Microsoft YaHei UI';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        color: #FFFFFF;
      }
    }

    .buttom {
      width: 140px;
      height: 32px;
      background: linear-gradient(93.53deg, #FFDE9F 2.9%, #FFCB77 48.93%, #FFB341 96.67%), #5452F6;
      box-shadow: inset 2px 2px 3px #FFFBD7;
      border-radius: 35px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      /* 2.0/一级文字-2.0 */
      margin-top: 14px;
      float: right;
      color: #1D1D1F;
      cursor: pointer;

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(93.56deg, #FFDE9F 2.93%, #FFD187 48.93%, #FFB341 96.64%);
      }
    }
  }
</style>